@import '../../index.scss';


.section3 {
    background-color: #2b2b2b;
    position: relative;
    width: 100%;



    .container-section3 {
        width: 58.125rem;
        position: relative;
        margin: 0 auto;
        display: flex;
        gap: 2rem;

        @include mobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0.5rem;
        }

        .text {
            width: 50%;
            margin-top: 3rem;

            @include mobile {
                padding: 1rem;
                width: 90%;
                align-items: center;
                text-align: start;
            }

            span {
                letter-spacing: 0.4375rem;
                font-size: 0.8125rem;
                text-transform: uppercase;
                display: block;
                font-weight: 400;
                margin-bottom: 0.625rem;
                font-weight: 800 !important;
                color: #e64837
            }

            h1 {
                color: white;
                font-size: 1.5625rem;
            }

            p {
                color: white;
                line-height: 1.75rem;
                font-size: 0.9375rem;
            }
        }

        .images-section3 {
            width: 50%;

            @include mobile {
                width: 100%;

            }

            img {
                display: block;
                width: 28.125rem;

                @include mobile {
                    width: 95%;
                }
            }
        }
    }

}