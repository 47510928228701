@import '../../index.scss';

.contact2 {
    width: 100%;

    margin-bottom: 2rem;

    .contact2-container {
        width: 58.125rem;
        margin: 0 auto;
        position: relative;

        @include mobile {
            width: 100%;
            padding: 1rem;
        }

        .text {
            padding-bottom: 3rem;



            h1 {
                font-size: 2.5rem;

                @include mobile {
                    padding-bottom: 1rem;
                    width: 100%;
                }
            }

            p {
                @include mobile {
                    width: 95%
                }
            }
        }

        .info {
            display: flex;
            align-items: start;
            width: 100%;

            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: start;
            }

            .contact-info {
                @include mobile {
                    padding-bottom: 2rem;
                }


                p {
                    text-transform: uppercase;
                    font-size: 0.9375rem;
                    font-weight: 600;
                    border-bottom: 1px solid black;
                }

                span {
                    color: #303030;
                    line-height: 1.375rem;
                }
            }

            .icon-info {
                align-items: flex-start;
                display: flex;
                gap: 3.5rem;
                margin-left: 3.125rem;


                @include mobile {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    gap: 2rem;
                    align-items: start;
                }

                .item {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;

                    @include mobile {
                        display: flex;
                        flex-direction: column;

                    }


                    span {

                        font-weight: 600;
                        font-size: 1.25rem;
                    }

                    p {
                        color: #303030;
                        line-height: 1.5rem;
                        font-weight: 500;
                    }

                    .horizontal-line {
                        height: 0.25rem;
                        width: 1.875rem;
                        border-bottom: 0.3125rem solid black;
                        margin: 0.625rem 0px;
                        align-items: end;
                    }

                    &:hover {
                        .horizontal-line {
                            border-bottom: 0.3125rem solid rgb(207, 13, 13);

                        }
                    }
                }
            }
        }
    }
}