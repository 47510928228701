@import '../../index.scss';


.images-about {
    position: relative;
    width: 100%;
    height: 37.5rem;
    background-color: #333;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.container-section-about {
    position: absolute;
    display: flex;
    z-index: 1000;
    top: 0;
    left: 0;
    gap: 2.5rem;
    width: 100%;
    height: 100%;

    .logo {
        position: relative;
        top: 3.125rem;
        margin-left: 1.25rem;
    }

    .vertical-line1 {
        position: relative;
        width: 0.125rem;
        height: 37.5rem;
        border-left: 0.0625rem solid rgb(83, 81, 81);

        @include mobile {
            display: none;
        }
    }

    .text {
        position: relative;
        top: 12.5rem;

        @include mobile {
            position: absolute;
            align-items: center;
            text-align: center;
            padding: 1rem;
            top: 15rem;
        }

        h1 {
            font-size: 3.125rem;
            text-transform: uppercase;
            color: aliceblue;
            letter-spacing: 0.0625rem;
        }

        p {
            text-transform: capitalize;
            color: aliceblue;
            font-weight: normal;
        }
    }

    .vertical-line {
        margin-right: 1.25rem;
        width: 0.125rem;
        height: 100%;
        border-left: 0.0625rem solid rgb(83, 81, 81);

        @include mobile {
            display: none;
        }
    }

    .container-line {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37.5rem;
        margin: 0 26.25rem;

        @include mobile {
            display: none;
        }

        .vertical-line {
            width: 0.125rem;
            height: 100%;
            border-left: 0.0625rem solid rgb(83, 81, 81);
        }

        .horizontal-line {
            margin: 0px -1.375rem;
            height: 0.625rem;
            width: 2.5rem;
            border-bottom: 1.25rem solid rgb(163, 21, 21);
        }
    }
}