@import '../../index.scss';


.portfolio2{
    width: 100%;
    padding-bottom: 4rem;

    .portfolio2-container {
        width: 58.125rem;
        position: relative;
        margin: 0 auto;
    
        @include mobile {
            width: 100%;
            margin-bottom: 1rem;
            padding: 1rem;
        }
        .text {
            padding-bottom: 4rem;

            @include mobile {
                width: 97%;
                
            }
            h1 {
                font-size: 2.5rem;
                text-transform: uppercase;
                padding: 1rem 0 1rem;
                @include mobile {
                    font-size: 2rem;
                }
            }
            p {
                font-size: 0.875rem;
                text-transform: uppercase;
                font-weight: bold;
            }
            span {
                font-size: 0.875rem;
            }
        }
        .box-p {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2.5rem;

            @include mobile {
                display: flex;
                flex-direction: column;
                
            }
    
            .portfolio2-item {
                display: flex;
                gap: 1.25rem;
                text-align: justify;
    
                img {
                    width: 20rem;
                    height: 20rem;
                    object-fit: cover;
                }
            }
        }
    }
    
}