@import '../../index.scss';

.services3 {
    background-color: #303030;
    position: relative;
    // z-index: 9999 !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 435px;
    margin-bottom: 7.8125rem;

    @include mobile {
        width: 100%;
        height: 100%;
    }

    @include tablet {
        width: 100%;
        height: 27.5rem;
    }

    .services3-container {
        width: 58.125rem;
        position: relative;
        margin: 0 auto;
        display: flex;
        z-index: 99 !important;
        gap: 1.25rem;

        @include mobile {
            width: 100%;
            display: flex;
            flex-direction: column;

        }

        .services3-text {
            color: #fdfdfd !important;
            margin-top: 3.125rem;

            @include mobile {
                width: 90%;
                padding: 1rem;



            }

            span {
                letter-spacing: 0.3125rem;
                font-size: 0.8125rem;
                text-transform: uppercase;
                display: block;
                font-weight: 400;
                margin-bottom: 0.625rem;
                font-weight: 800 !important;
                color: #e64837
            }

            h3 {
                color: white;
                font-size: 1.5625rem;
                text-transform: uppercase;

                @include mobile {
                    margin-bottom: 1rem !important;
                }
            }

            p {
                color: white;
                line-height: 1.75rem;
                font-size: 0.875rem;
            }

            .btn {
                margin-top: 1.25rem;



                h2 {
                    text-transform: uppercase;
                    font-size: 0.875rem;
                    cursor: pointer;
                    color: white;

                    &:hover {
                        color: #e64837;
                        transition: all 0.3s;
                    }
                }


            }
        }

        .services3-images {
            width: 50%;

            @include mobile {
                width: 100%;
            }

            img {
                margin-top: -1.25rem;
                height: 30.25rem;
                width: auto;

                @include mobile {
                    width: 90%;
                    padding: 1rem;
                    height: auto;
                }
            }
        }
    }

}