@function themed($key) {
    @return map-get($theme-map, $key);
  }
  
  @mixin mobile {
    @media (max-width: 480px) {
      @content;
    }
  }
  @mixin tablet {
    @media (max-width: 960px) {
      @content;
    }
  }