@import '../../index.scss';

.section4 {
    background-color: #303030;
    position: relative;
    // z-index: 9999 !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 31.1875rem;

    @include mobile {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .container-section4 {
        width: 58.125rem;
        position: relative;
        margin: 0 auto;
        display: flex;
        gap: 2rem;

        @include mobile {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            gap: 0;
        }

        .text {
            color: #fdfdfd !important;
            margin-top: 5rem;
            margin-left: 1rem;
            width: 100%;

            @include mobile {

                width: 90%;
                align-items: center;

            }

            span {
                letter-spacing: 0.2375rem;
                font-size: 0.8125rem;

                display: block;
                font-weight: 400;
                margin-bottom: 0.625rem;
                font-weight: 800 !important;
                color: #e64837
            }

            h1 {
                color: white;
                font-size: 1.5625rem;

                @include mobile {
                    width: 100%;
                }
            }

            p {
                color: white;
                line-height: 1.75rem;
                font-size: 0.9375rem;
            }
        }

        .images-section4 {

            display: block;
            margin-left: auto;
            margin-right: auto;

            img {
                margin-top: 9rem;
                height: 25rem;
                width: 28.125rem;

                @include mobile {
                    padding: 1rem;
                    margin-top: 1rem;
                    height: auto;
                    width: 90%;

                }
            }
        }
    }

}