@import '../../index.scss';

.section2 {
    background-color: whitesmoke;
    width: 100%;
    padding-bottom: 3rem;

    .container {
        width: 58.125rem; /* 930px converted to rem */
        position: relative;
        margin: 0 auto;
        padding: 4rem 0rem;
        @include mobile {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
        }
    
        .text {
            margin-top: 3rem;
            span {
                font-size: 0.9375rem; /* 15px converted to rem */
                font-weight: 400;
                
                margin-bottom: 1rem;
            }
            h1 {
                font-size: 2.625rem; /* 42px converted to rem */
                line-height: .8;
                padding: 2rem 0rem;
            }
            p {
                font-weight: bolder;
                font-size: 0.875rem; /* 14px converted to rem */
                margin-bottom: 4rem;
            }
        }
    
        .images {
            display: flex;
            grid-template-columns: repeat(2fr, 1fr);
            grid-gap: 20px;

            @include mobile {
                display: flex;
                flex-direction: column !important;
                width: 90%;
                padding: 1rem;
                height: auto;
            }
            
            img {
                width: 28.125rem; /* 450px converted to rem */
                @include mobile {

                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
            
// .image--wrapper {
//     position: relative;
//     &:hover {
//         h4 {
//             visibility: visible;
//             opacity: 1;
//         }
//     }
//     h4 {
//         visibility: hidden;
//         opacity: 0;
//         background-color: red;
//         color: white;
//         position: absolute;
//     top: 0;
//     height: calc(100% - 65px);
//     margin: 30px;
//     width: calc(100% - 65px);
//     text-align: center;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 45px;
//     font-weight: 800;
//     transition: all 0.5s ease;
//     }
// }
    
            .img-container {
                display: grid;
                grid-gap: 1rem;

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                img {
                    height: 20.5rem;
                    @include mobile {
                        width: 100%;
                    }
                }
            }
        }
    }
    
}