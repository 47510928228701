@import '../../index.scss';

.portfolio5 {
    width: 100%;

    .portfolio5-container {
        width: 930px;
        position: relative;
        margin: 0 auto;
        padding-bottom: 7rem;

        @include mobile {
            width: 100%;
            
        }

        .text {
            padding-bottom: 4rem;

            @include mobile {

                width: 100%;
                padding: 0.7rem;
                
            }
            h1 {
                font-size: 2rem;
                text-transform: uppercase;
                padding-top: 1rem;
            }
            p {
                font-size: 0.875rem;
                text-transform: uppercase;
                font-weight: bold;
            }
            span {
                font-size: 0.875rem;
            }
        }

        .portfolio5-text {
            display: flex;
            justify-content: space-around;
            padding-bottom: 2rem;

            @include mobile {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                padding: 0.7rem;
            }
            .item {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include mobile {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    
                    
                }
                p{
                    width: 100%;
                    letter-spacing: 0.03rem;
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                }

                h2{
                    font-weight: 700;
                    font-size: 21px;
                    padding-bottom: 1rem;
                    text-transform: uppercase;

                }
            }
        }

        .swiper {
            border-radius: 5px;
        .img-swiper1 {
            width: 100%;
            height: 34rem;
            @include mobile {
                width: 100%;
                height: 100%;
                padding: 0.01;
                align-items: center;
            }

            @include tablet {
                width: 100%;
                height: 50rem;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    }
}