@import '../../index.scss';


.contact4 {
    width: 100%;
    margin-bottom: 4rem;

    .contact4-container {
        width: 57.5rem;
        margin: 0 auto;
        position: relative;
        z-index: 999;
        align-items: center;

        @include mobile {
            width: 100%;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3rem;


            .label {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                @include mobile {
                    align-items: center;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
            }

            @include mobile {
                width: 100%;
                display: flex;
                flex-direction: column;
            }


            h1 {
                font-size: 1.5rem;

                @include mobile {
                    align-items: center;
                    text-align: center;
                }
            }

            input {
                padding: 0.9375rem 0.9375rem;
                width: 31.25rem;
                border: 1px solid gray;
                font-size: 15px;
                border-radius: 0.5rem;

                @include mobile {
                    width: 60%;

                }

                &:hover {
                    border: 0.125rem solid red;
                }
            }

            textarea {
                padding: 0.9375rem;
                width: 31.25rem;
                border-radius: 0.5rem;
                font-size: 15px;
                border: 1px solid gray;

                @include mobile {
                    width: 60%;
                    height: auto;
                }

                &:hover {
                    border: 0.125rem solid red;
                }
            }

            button {
                padding: 0.9375rem;
                border: none;
                background-color: brown;
                color: white;
                font-weight: 600;
                cursor: pointer;
                border-radius: 0.5rem;
            }

        }
    }
}