@import '../../index.scss';

.services4 {
    width: 100%;
    z-index: 999;
    margin-bottom: 5rem;
    .services4-container {
        width: 58.125rem;
        margin: 0 auto;
        position: relative;
        display: flex;
        gap: 6rem;
        align-items: center;

        @include mobile {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
        }

        .text{
            width:50%;

            @include mobile {
                width: 100%;
                
            }
            h1{
                font-size: 2.8125rem;
                margin-bottom: 1rem;
                @include mobile {
                    font-size: 2rem;
                }
            }
            p{
                font-size: 0.875rem;
                letter-spacing: 0.1rem;
                line-height: 1.6875rem;
                color: #303030;
                margin-bottom: 1rem;
                @include mobile {
                width: 90%;
            }
            }
            .stars{
                cursor: pointer;
            }
        }

        .counter {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 5rem;
            margin-top: 2rem;

            @include mobile {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: start;
            margin-top: 0;
        }
            .counter-1{
                display: flex;
                flex-direction: column;
                gap: 0.3125rem;
                @include mobile {
                    align-items: flex-start;
                }
                span{
                    font-size: 2.1875rem;
                    text-align: end;
                    color: red;
                    font-weight: 600;
                }
                h1{
                    font-size: 0.9375rem;
                }
                .horizontal-line {
                    height: 0.25rem;
                    width: 1.875rem;
                    border-bottom: 0.3125rem solid black;
                    margin: 0.625rem 0px;
                    align-items: end;
                }
                &:hover{
                    .horizontal-line {
                        border-bottom: 0.3125rem solid rgb(207, 13, 13);
        
                    }
                }
            }
        }
    }
}
