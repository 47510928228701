@import '../../index.scss';


.section4-about{
    background-color: white;
    width: 100%;
    margin-bottom: auto;
    .section4-container {
        width: 58.125rem;
        
        position: relative;
        margin: 0 auto;
        margin-bottom: 8rem;
        padding-top: 0.5rem;

        @include mobile {
            width: 100%;
            margin-bottom: 3rem;
        }

        .text-section4{
            margin: 4rem 0px 0px 0px;
            margin-bottom: 4rem;

            @include mobile {
            width: 90%;
            padding: 1rem;

            
        } 
            h1{
                font-size: 2.5rem;
            }

            p{
                font-size: 0.875rem;
                text-transform: uppercase;
                font-weight: bold;
            }
            span{
                font-size: 0.875rem;
            }

        }
        .box-section4{
            display: flex;
            
            @include tablet {
                gap: 2rem;
            }


            @include mobile {
                width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 2rem;
            gap: 2rem;
        }
        

            .item-section4{
                width: 50%;
                @include mobile {
            width: 100%;
            align-items: center;
            
        }

        
                .images-team{

                    img{
                    @include tablet {
                        width: 100%;
                        
                    }
                }

                }

                .item-text-team{
                    width: 15rem;
                    align-items: center;

                    @include mobile {
                        width: 80%;
                    }

                    @include tablet {
                        width: 95%;
                    }
                    h1{
                        font-weight: normal;
                        font-size: 1.5rem;
                        margin-bottom: 0.5rem;

                    }
                    
                    p{
                        color: gray;
                        margin-bottom: 1rem;
                    }
                    span{
                        letter-spacing: 0.03125rem;
                        line-height: 1.375rem;
                    }
                    .horizontal-line {
                        height: 0.25rem;
                        width: 1rem;
                        border-bottom: 0.3125rem solid black;
                        margin: 0.625rem 0px;
                    }
                    
                    

                }
                &:hover{
                    .horizontal-line {
                        border-bottom: 0.3125rem solid rgb(207, 13, 13);

                    }
                }
                
            }
        }
    }
}
