@import "../../index.scss";

body.no-scroll {
  overflow-y: hidden;
}


.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.75rem;
  color: #fff;
  z-index: 99999;
  position: absolute;
  top: 3.75rem;
  right: 0;
  left: 0;
  padding-right: 3.75rem;
  text-transform: capitalize;

  @include mobile {
    padding-right: 1rem;
  }

  &.show-nav {
    position: fixed;
  }
}



.icon {
  cursor: pointer;

}

.navbar-nav {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  background-color: black !important;
  position: absolute;
  @include rem(top, -60px);
  right: 0;
  width: 25%;
  height: 100vh;
  transition: all 0.5s ease-out;
  position: absolute;
  right: -1000px;
  visibility: hidden;
  opacity: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  @include mobile {
    width: 70%;
  }

  &.show-nav {
    display: flex;
    right: 0;
    visibility: visible;
    opacity: 1;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  margin: 2.55rem;
  text-decoration: underline;


  &:hover {
    color: red;
  }

  &:first-child {
    @include rem(margin-top, 185px);
  }
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.125rem;
}

.btn-nav {
  padding: 1.5rem;
  display: flex;
  gap: 0.5rem;

  button {
    background-color: red;
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: none;

    &:hover {
      background-color: whitesmoke;
      color: black;
      transition: 0.3s easy;
    }
  }
}