@use 'sass:math';
@import './style.scss';
@import 'aos/dist/aos.css';

.link {
  text-decoration: none;
  color: #706969;

  &:hover {
    color: red;
  }
}

.scroll-to-top {
  background-color: green !important;
  color: white !important;

  @include mobile {
    display: flex;
    align-items: center;


  }

  svg {
    height: 23px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

* {
  font-family: "Poppins", sans-serif !important;
}

html {
  overflow-x: hidden !important;
  // scroll-behavior: smooth !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

@mixin rem($property, $values, $important: false) {
  // Create a couple of empty lists as output buffers.
  $base-font-size: 16px; // should be consistent with your html/body font-size
  $px-values: ();
  $rem-values: (); // Loop through the $values list

  @each $value in $values {

    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value==0 or $value==0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    }

    @else if type-of($value)==number and not unitless($value) and (unit($value)==px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: math.div($value, $base-font-size);
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    }

    @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value * $base-font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  // output the converted rules
  @if $important==true {
    // #{$property}: $px-values !important;
    #{$property}: $rem-values !important;
  }

  @else {
    // #{$property}: $px-values;
    #{$property}: $rem-values;
  }
}

@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }

  @media only screen and (min-device-width: 360px) and (max-device-width: 767px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobileLandscape {
  @media only screen and (min-device-width: 360px) and (max-device-width: 767px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    @content;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media only screen and (min-width: 991px) and (max-width: 1199px) {
  html {
    font-size: 90% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  html {
    font-size: 59% !important;
  }
}

@include mobile {
  img {
    max-width: 100% !important;
  }
}