@import '../../index.scss';


.section2-about{
    width: 100%;
    background-color: white;
    padding-top: 4rem;
    .section2-container {
        width: 58.125rem; /* 930px */
        position: relative;
        margin: 0 auto;
        margin-bottom: 3.75rem; /* 6rem */
        @include mobile {
            width: 100%;
            align-items: center;
            text-align: center;
        }
        .text{
            margin-bottom: 2.5rem; /* 4rem */

            @include mobile {
                align-items: center;
                width: 90%;
                padding: 1rem;
            }
            h1{
                font-size: 2.5rem; /* 40px */
                margin-bottom: 1rem;
            }

            p{
                font-size: 0.875rem; /* 14px */
                text-transform: uppercase;
                font-weight: bold;
            }
            span{
                font-size: 0.875rem; /* 14px */
            }

        }
        .story-section2{
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 3.5625rem; /* 25px */
            padding-top: 2rem;

            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2rem;
                width: 100%;
                padding-top: 0;
            }
            .item {
                display: flex;
                align-items: start;
                gap: 1.5625rem; /* 25px */

                @include mobile {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
                .year {
                    span {
                        font-size: 2.5rem; /* 40px */
                    }
                }
                .box {
                    width: 50%;
                    // display: flex;
                    // flex-direction: column;
                    align-items: center;

                    @include mobile {
                        width: 100%;
                        align-items: start;
                        text-align: start;
                    }

                    h2{
                        font-size: 1rem; /* 16px */
                        text-transform: uppercase;
                        
                    }
                    p{
                        text-align: justify;
                        letter-spacing: 0.3125px; /* 0.5px */
                        font-size: 0.8125rem; /* 13px */
                        line-height: 1.5625rem; /* 25px */
                        
                    }
                } 

                .box {
                    width: 50%;
                    // display: flex;
                    // flex-direction: column;
                    align-items: center;

                    @include mobile {
                        align-items: start;
                        width: 80%;
                        display: flex;
                        flex-direction: column;
                    }
                    h3{
                        text-align: end;
                        align-items: end;
                        font-size: 1rem; /* 16px */
                        text-transform: uppercase;
                        cursor: pointer;
                        &:hover  {
                            color: red;
                        }
                    }
                    p{
                        text-align: justify;
                        letter-spacing: 0.3125px; /* 0.5px */
                        font-size: 0.8125rem; /* 13px */
                        line-height: 1.5625rem; /* 25px */
                        @include mobile {
                            text-align: start;
                        }
                        
                    }
                } 
            }
        }
    }
}
