@import '../../index.scss';

.img-swiper {
    position: relative;
    width: 100%;
    height: 38.5rem;
    box-shadow: 10px 10px 20px #333;
    background-color: #333;

    @include mobile {
        height: 36rem;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.container-swiper {
    position: absolute;
    display: flex;
    z-index: 1000;
    top: 0;
    left: 0;
    gap: 2.5rem;
    width: 100%;
    height: 100%;

    @include mobile {
        width: 100%;
    }

    .logo {
        position: relative;
        top: 3.125rem;
        margin-left: 1.25rem;
        max-width: 10rem;
        // @include rem(padding-right, 20px);
    }

    .vertical-line1 {
        position: relative;
        width: 0.125rem;
        height: 100%;
        border-left: 0.0625rem solid rgb(83, 81, 81);

        @include mobile {

            display: none;
        }
    }

    .text {
        position: relative;
        top: 12.5rem;
        margin-left: 1.875rem;

        @include mobile {
            width: 100%;

            margin: 0;
            text-align: center;
            position: absolute;


        }



        h1 {
            font-size: 2.8125rem;
            text-transform: uppercase;
            color: aliceblue;
            letter-spacing: 0.0625rem;

            @include mobile {
                font-size: 2.5rem;
            }
        }

        p {
            text-transform: capitalize;
            color: aliceblue;
            font-weight: normal;
        }
    }

    .vertical-line {
        margin-right: 1.25rem;
        width: 0.125rem;
        height: 100%;
        border-left: 0.0625rem solid rgb(83, 81, 81);

    }

    .container-line {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0 23.4375rem;

        @include mobile {

            display: none;
        }


        .vertical-line {
            width: 0.125rem;
            height: 100%;
            border-left: 0.0625rem solid rgb(83, 81, 81);
        }

        .horizontal-line {
            margin: 0px -1.375rem;
            height: 0.625rem;
            width: 2.5rem;
            border-bottom: 1.25rem solid rgb(163, 21, 21);
        }
    }
}


.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--swiper-theme-color);
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}