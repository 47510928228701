@import '../../index.scss';    

.service2{
    width: 100%;
background-color: whitesmoke;
height: 100%;
padding-top: 4rem;
padding-bottom: 7rem;
    .service2-container{
        width: 58.125rem;
position: relative;
margin: 0 auto;


@include mobile {
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
}



.text{
    margin-bottom: 4rem;
    @include mobile {

        width: 90%;
        
    }
    h1{
        font-size: 2.5rem;
        @include mobile {
            margin-bottom: 1rem !important;
        }
    }

    p{
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: bold;
        @include mobile {
            margin-bottom: 0.1rem !important;
        }
    }
    span{
        font-size: 0.875rem;
    }

}
.box-services2{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    grid-gap: 2.5rem;
    
    @include mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    
    
    .item-services2{
        display: flex;
        gap: 1.25rem;
        
        
        @include mobile {
            width: 80%;
            
        }
        

        .icon{
            margin-top: 0.625rem;
            display: block;
        }
        .text-service2{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            
            h4{
                text-transform: uppercase;
                padding-bottom: 0.4rem;
                font-weight: 600;
            }
            p{
                font-weight: lighter;
                font-size: 0.875rem;
                line-height: 1.5625rem;
                
            }
        }
        .horizontal-line {
            height: 0.25rem;
            width: 1.875rem;
            border-bottom: 0.3125rem solid black;
            margin: 0.625rem 0px;
        }
        &:hover{
            .horizontal-line {
                border-bottom: 0.3125rem solid rgb(207, 13, 13);
            }
        }
    }
    
}

}

        
    }
