@import '../../index.scss';

.impresum2 {
    width: 100%;

    .impresum2-container {
        width: 58.125rem; /* 930px converted to rem */
        position: relative;
        margin: 0 auto;
        padding: 4rem 0rem;

        @include mobile {
            width: 100%;
            padding: 0.6rem;
        }

        .impresum2-item {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            
                h1{
                    font-size: 2rem;
                    padding-bottom: 0.5rem;
                    @include mobile {
                        
                        font-size: 1.5rem;
                    }
                }
                p{
                    font-size: 0.9rem;
                    @include mobile {
                        width: 90%;
                        font-size: 0.8rem;
                    }
                }

                span{
                    @include mobile {
                        width: 90%;
                    }
                }

                ul {
                    padding: 0.9rem;
                    @include mobile {
                            width: 90%;
                            
                        }
                    li{
                        font-size: 0.9rem;
                        
                    }
                }
            
                .impresum-box17{
                    h3{
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
        }
    }
}