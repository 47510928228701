@import '../../index.scss';


.section3-about{
    background-color: #303030;
    width: 100%;
    height: 30.375rem;

    @include mobile {
        height: 100%;
    }
    .section3-container {
        width: 58.125rem;
        
        position: relative;
        margin: 0 auto;
        margin-bottom: 8rem;
        padding-top: 1.25rem;
        color: white;

        @include mobile {
            width: 100%;
            margin-bottom: 1rem;
            padding: 1rem;
        }

        .text-section3{
            color: white;
            margin-bottom: 4rem;

            @include mobile {
                width: 90%;
                
            }
            h1{
                font-size: 2.5rem;
            }

            p{
                font-size: 0.875rem;
                text-transform: uppercase;
                font-weight: bold;
            }
            span{
                font-size: 0.875rem;
            }

        }
        .box-section3{
            display: flex;
            justify-content: space-around;
            gap: 2.5rem;

            @include mobile {
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .item-section3{
                display: flex;
                gap: 1.25rem;
                text-align: start;
width: 100%;
                .text-box{
                    
                    h3{
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                    p{
                        font-weight: normal;
                        line-height: 1.5625rem;
                        font-size: 0.8125rem;
                        padding-bottom: 1rem;
                        @include mobile {
                            width: 80%;
                            
                        }
                    }
                    .horizontal-line {
                        height: 0.25rem;
                        width: 2.5rem;
                        border-bottom: 0.3125rem solid white;
                        margin: 0px;
                        margin-bottom: 1rem;
                    }
                    &:hover{
                        .horizontal-line {
                            border-bottom: 0.3125rem solid rgb(207, 13, 13);

                        }
                    }
                }
            }
        }
    }
}
