@import '../../index.scss';


.footer {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: #2b2b2b;
    color: #fff;

    .container-footer {
        display: flex;
        justify-content: center;
        justify-content: space-between;

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    
        .logo {
            margin-top: 1.25rem;
            padding: 3.125rem;
        }
    
        .about-footer {
            padding: 3.125rem;
            margin-top: 1.25rem;
            width: 50%;
            border-left: 0.03125rem solid gray;

            @include mobile {
            padding: 0rem;
            width: 90%;
            border: none;
            
        }
            h1{
                font-size: 1.5rem;
            }
            p{
                font-size: 0.875rem;
                letter-spacing: 0.03125rem;
                text-align: justify;
                line-height: 1.5625rem;
            }
    
            span{
                text-transform: uppercase;
                font-size: 0.875rem;
                font-weight: 700;
    
                &:hover {
                    color: #e64837;
                }

                
                
            }
    
            .social-footer{
                margin-top: 2.5rem;
                display: flex;
                gap: 1.25rem;
                color: #706969 !important;
                cursor: pointer;
                flex-wrap: wrap;

            }
        }
    
        .newsletter-footer{
            padding: 3.125rem;
            margin-top: 1.25rem;
            width: 50%;
            border-left: 0.03125rem solid gray;
    

            @include mobile {
            width: 90%;
            border: none;
            @include rem(padding, 20px);
            
            
        }
            h1{
                text-transform: uppercase;
                font-size: 1.375rem;
                letter-spacing: 0.0625rem;
            }
            p{
                line-height: 1.5625rem;
                letter-spacing: 0.03125rem;
                font-size: 0.875rem;
            }
            input {
                padding: 0.9375rem;
                width: 18.75rem;
                @include mobile {
                    margin-bottom: 0.5rem;
                }
            }
            button{
                padding: 1rem 1.5625rem;
                border: none;
                background-color: green;
                color: white;

                @include mobile {
                    align-items: center;
                    border-radius: 5px;
                }
            }
        }
    }
    
    
}