@import '../../index.scss';

.section6 {
    background-color: whitesmoke;
    width: 100%;
    padding-top: 4rem;    

    .container-section6 {
        width: 47rem;
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include mobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 2rem;
        }

        .text-section6 {
            margin-top: 4rem;

            @include mobile {
            text-align: center;
            
        }

            p {
                font-size: 0.875rem;
                text-transform: uppercase;
                color: #e64837;
                font-weight: bolder;
                letter-spacing: 0.5rem;

                @include mobile {
                    padding-bottom: 0.5rem;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 1.3125rem;
                margin-bottom: 5rem;
                letter-spacing: 0.0125rem;
                font-weight: 600;
                @include mobile {
            margin-bottom: 1.5rem;
            
        }
            }
        }

        .btn {
            button {
                padding: 0.9375rem 1.875rem;
                border: none;
                background-color: #e74c3c !important;
                color: white;
                text-transform: uppercase;
                font-weight: 800;
                letter-spacing: 0.03125rem;
                cursor: pointer;

                &:hover {
                    background-color: royalblue !important;
                    opacity: 1;
                    transition: 0.6s ease-in-out;
                    border-radius: 10px;
                }
                
            }
            
        }
    }
}
